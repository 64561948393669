<template>
  <div
    v-if="displayLocaleSwitcher"
    class="nav-mobile-locale-switcher"
    :class="{ 'nav-mobile-locale-switcher--active': open }"
  >
    <div class="nav-mobile-locale-switcher__active" @click="openLocaleSwitcher">
      <div class="nav-mobile-locale-switcher__flag">
        <NavFlagIcon :iso-code="activeLocaleObject.locale" />
      </div>

      <span>{{ activeLocaleObject.language }}</span>

      <span>{{ translation.localeSwitcherChange }}</span>
    </div>

    <div
      class="nav-mobile-locale-switcher__close"
      @click="closeLocaleSwitcher"
    />

    <div class="nav-mobile-locale-switcher__list-container">
      <div class="nav-mobile-locale-switcher__list-wrapper">
        <div class="nav-mobile-locale-switcher__list-header">
          <span>{{ translation.localeSwitcherHeader }}</span>
          <span @click="closeLocaleSwitcher">{{
            translation.localeSwitcherCancel
          }}</span>
        </div>

        <ul class="nav-mobile-locale-switcher__list">
          <li
            v-for="locale in localeNavigation"
            :key="locale.locale"
            class="nav-mobile-locale-switcher__list-item"
            @click="displayLink(locale) ? handleLinkClick() : null"
          >
            <component
              :is="displayLink(locale) ? 't3-link' : 'div'"
              :to="displayLink(locale) ? locale.localePath : null"
              class="nav-mobile-locale-switcher__list-link"
              :class="{
                'nav-mobile-locale-switcher__list-link--disabled':
                  !locale.available
              }"
            >
              <NavFlagIcon :iso-code="locale.locale" />

              <span>{{ locale.language }}</span>

              <SvgIconCheck v-if="locale.active" />
            </component>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIconCheck from '~ui/assets/icons/icon-check.svg'

import NavFlagIcon from '../NavComponents/NavFlagIcon.vue'

export default {
  components: { NavFlagIcon, SvgIconCheck },
  props: {
    localeNavigation: {
      type: Array,
      default: () => []
    },
    open: {
      type: Boolean,
      default: false
    },
    translation: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    displayLocaleSwitcher() {
      return this.localeNavigation.length > 1
    },
    activeLocaleObject() {
      return this.localeNavigation.find(({ active }) => active)
    }
  },
  methods: {
    handleLinkClick() {
      this.closeLocaleSwitcher()
      this.$emit('link-click')
    },
    openLocaleSwitcher() {
      this.$emit('toggle', true)
    },
    closeLocaleSwitcher() {
      this.$emit('toggle', false)
    },
    displayLink({ active, available }) {
      return !(active || !available)
    }
  }
}
</script>

<style lang="scss">
.nav-mobile-locale-switcher {
  $base: &;

  position: relative;

  &__close {
    position: fixed;
    inset: 0;
    background-color: rgba(color(tarawera), 32%);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition:
      opacity 0.4s,
      visibility 0.4s;
  }

  &--active &__close {
    pointer-events: all;
    opacity: 1;
    visibility: visible;
  }

  &__active {
    display: flex;
    align-items: center;
    gap: rem(12px);
    background-color: var(--bg-200);
    padding: rem($container-padding);
    color: var(--font-300);

    & > span:last-child {
      margin-left: auto;
    }
  }

  &__list {
    &-header {
      display: flex;
      justify-content: space-between;
      padding-inline: rem($container-padding);
      padding-block: calc(rem($container-padding) * 1.5)
        calc(rem($container-padding) / 2);

      & > span:first-child {
        font-weight: 600;
        font-size: rem(16px);
        color: var(--font-300);
      }

      & > span:last-child {
        font-weight: 500;
        font-size: rem(13px);
        text-transform: uppercase;
        color: var(--font-100);
      }
    }

    &-item {
      & > * {
        color: var(--font-100);
      }

      &:nth-child(even) {
        background-color: var(--bg-200);

        & > * {
          color: var(--font-300);
        }

        & .nav-flag-icon {
          border: 1px solid rgba(color(tarawera), 32%);
        }
      }
    }

    &-link {
      padding: rem($container-padding);
      display: flex;
      align-items: center;
      gap: rem(12px);

      & svg {
        margin-left: auto;

        & path {
          fill: color(primary);
        }
      }

      &--disabled {
        opacity: 0.3;
      }
    }
  }

  &__list-container {
    display: grid;
    grid-template-rows: 0fr;
    align-content: end;
    position: fixed;
    bottom: var(--overlay-position, 0);
    width: 100%;
    background-color: var(--bg-100);
    border-radius: em(8px) em(8px) 0 0;
    visibility: hidden;
    overflow: hidden;
    transition:
      grid-template-rows 0.2s,
      visibility 0.2s;
  }

  &__list-wrapper {
    min-height: 0;
  }

  &--active &__list-container {
    grid-template-rows: 1fr;
    visibility: visible;
  }
}
</style>
