<template>
  <div
    class="nav-mobile-link-container"
    :class="{ 'nav-mobile-link--subdrop-open': open }"
  >
    <div class="nav-mobile-link-wrapper">
      <T3Link :to="getLink(link)" class="nav-mobile-link" active-class>
        <span @click="handleLinkClick">
          {{ link.title }}
        </span>
      </T3Link>

      <div
        v-if="subdrop"
        class="nav-mobile-link-arrow"
        @click="handleArrowClick"
      >
        <SvgIconArrowMenu />
      </div>
    </div>

    <div
      v-if="subdrop"
      class="nav-mobile-link-subdrop"
      :style="{ '--subdrop-length': sudropLength }"
    >
      <NavMobileSubdrop
        :link-array="link.children"
        @link-click="handleLinkClick"
      />
    </div>
  </div>
</template>

<script>
import SvgIconArrowMenu from '~ui/assets/icons/icon-arrow-menu.svg'

import NavMobileSubdrop from './NavMobileSubdrop.vue'

export default {
  components: { NavMobileSubdrop, SvgIconArrowMenu },
  props: {
    link: {
      type: Object,
      default: () => ({})
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    subdrop() {
      return Array.isArray(this.link.children) && this.link.children.length
    },
    sudropLength() {
      return this.link.children.reduce(
        (count, link) => count + (link.spacer === 1 ? link.children.length : 0),
        this.link.children.length
      )
    }
  },
  methods: {
    getLink({ link, target }) {
      return { link, target }
    },
    handleLinkClick() {
      this.$emit('link-click')
    },
    handleArrowClick() {
      this.$emit('toggle-subdrop', this.link.id)
    }
  }
}
</script>

<style lang="scss">
.nav-mobile-link {
  &-container {
    font-size: rem(20px);
    font-weight: 600;
    padding-inline: rem($container-padding);
  }

  &-wrapper {
    display: flex;
    align-items: center;

    & > *:first-child:last-child {
      flex-grow: 1;
    }
  }

  color: var(--font-200);
  text-underline-offset: 4px;
  transition: color 0.4s;

  &:is(:hover, :active, :focus) {
    color: inherit;
  }

  &.router-link-exact-active {
    text-decoration: 2px underline;
  }

  &-container:not(&--subdrop-open):has(
      &.router-link-active:not(&.router-link-exact-active)
    ) {
    background-color: var(--bg-200);
  }

  :where(:not(&--subdrop-open))
    &.router-link-active:not(&.router-link-exact-active) {
    color: #063952;
    text-decoration: 2px underline;
  }

  &--subdrop-open & {
    color: #1e242e;
    text-decoration: 2px underline;
  }

  & > span {
    display: block;
    padding-block: rem($container-padding);
  }

  &-arrow {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    height: 100%;

    & svg {
      transition: rotate 0.4s;
      height: 32px;
      width: 32px;

      & path {
        fill: #a0a7b3;
        transition: fill 0.4s;
      }
    }
  }

  &--subdrop-open &-arrow svg {
    rotate: 180deg;

    & path {
      fill: color(primary);
    }
  }

  :where(:not(&--subdrop-open))
    &.router-link-active:not(&.router-link-exact-active)
    ~ &-arrow
    svg
    path {
    fill: color(primary);
  }

  &-subdrop {
    --transition-duration: calc(var(--subdrop-length, 1) / 10 * 0.2s);

    display: grid;
    align-content: start;
    grid-template-rows: 0fr;
    visibility: hidden;
    overflow: hidden;
    background-color: var(--bg-200);
    border-radius: em(10px);
    transition:
      grid-template-rows var(--transition-duration),
      visibility var(--transition-duration);
  }

  &-subdrop .nav-mobile-subdrop {
    min-height: 0;
  }

  &--subdrop-open &-subdrop {
    grid-template-rows: 1fr;
    visibility: visible;
    margin-bottom: rem($container-padding);
  }
}
</style>
