<template>
  <ul class="nav-mobile-subdrop">
    <li v-for="link in linkArray" :key="link.title">
      <template v-if="!link.spacer">
        <T3Link :to="getLink(link)" class="nav-mobile-subdrop__link">
          <span @click="handleLinkClick">
            {{ link.title }}
          </span>
        </T3Link>
      </template>

      <div v-else class="nav-mobile-subdrop__group">
        <span>{{ link.title }}</span>

        <ul v-if="subdrop(link.children)">
          <li v-for="sublink in link.children" :key="sublink.title">
            <T3Link :to="getLink(sublink)" class="nav-mobile-subdrop__link">
              <span @click="handleLinkClick">
                {{ sublink.title }}
              </span>
            </T3Link>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    linkArray: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    getLink({ link, target }) {
      return { link, target }
    },
    handleLinkClick() {
      this.$emit('link-click')
    },
    subdrop(array) {
      return Array.isArray(array) && array.length
    }
  }
}
</script>

<style lang="scss">
.nav-mobile-subdrop {
  $border-color: #d0d6de;

  & li + li {
    border-top: 1px solid $border-color;
  }

  &__link {
    display: block;
    font-size: rem(18px);
    font-weight: 500;
    color: var(--font-300);

    &:is(:hover, :active, :focus) {
      color: inherit;
    }

    &.router-link-exact-active {
      text-decoration: 2px underline color(primary);
      text-underline-offset: 4px;
      font-weight: 600;
    }

    & > span {
      display: block;
      padding: rem($container-padding);
    }
  }

  &__group > span {
    display: block;
    color: color(primary);
    font-weight: 500;
    font-size: rem(16px);
    text-transform: uppercase;
    padding-inline: rem($container-padding);
    padding-block: calc(rem($container-padding) * 1.5)
      calc(rem($container-padding) / 2);
    border-bottom: 1px solid $border-color;
  }
}
</style>
