<template>
  <div class="nav-mobile-container">
    <input id="navMobileToggle" v-model="isNavMobileOpen" type="checkbox" />

    <label for="navMobileToggle">
      <NavToggle open />
    </label>

    <div class="nav-mobile-wrapper">
      <nav
        class="nav-mobile"
        :class="[
          { 'nav-mobile--scroll-locked': isNavMobileLocaleSwitcherOpen },
          { 'nav-mobile--with-footer': buttonNavigation.length }
        ]"
        :style="{ '--footer-size': buttonNavigation.length || null }"
      >
        <div class="nav-mobile__header">
          <T3Link
            :to="homeLink"
            :aria-label="translation.goHome"
            class="nav-mobile__logo"
          >
            <span @click="closeNavMobile">
              <Logo />
            </span>
          </T3Link>

          <label for="navMobileToggle">
            <NavToggle close />
          </label>
        </div>

        <div ref="navMobile" class="nav-mobile__scrollable">
          <ul v-if="mainNavigation.length" class="nav-mobile__list">
            <li v-for="(link, index) in mainNavigation" :key="link.title">
              <NavMobileLink
                :link="{ ...link, id: index }"
                :open="navMobileLinkIdArray.includes(index)"
                @link-click="closeNavMobile"
                @toggle-subdrop="handleNavMobileLinkSubdropToggle"
              />
            </li>
          </ul>

          <div v-if="socialNavigation.length" class="nav-mobile__social">
            <p>{{ translation.socialListHeader }}</p>

            <ul class="nav-mobile__social-list">
              <li v-for="link in socialNavigation" :key="link.title">
                <NavSocialLink :link="link" @click="closeNavMobile" />
              </li>
            </ul>
          </div>

          <NavMobileLocaleSwitcher
            :locale-navigation="localeNavigation"
            :open="isNavMobileLocaleSwitcherOpen"
            :translation="translation"
            @link-click="closeNavMobile"
            @toggle="handleNavMobileLocaleSwitcherToogle"
          />
        </div>

        <ul
          v-if="buttonNavigation.length"
          ref="navMobileFooter"
          class="nav-mobile__footer"
        >
          <li
            v-for="button in buttonNavigation"
            :key="button.content"
            @click="closeNavMobile"
          >
            <ButtonBase :button="button" />
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll
} from 'body-scroll-lock-upgrade'

import { ButtonBase } from '~ui/components'

import NavMobileLink from './NavMobileLink.vue'
import NavMobileLocaleSwitcher from './NavMobileLocaleSwitcher.vue'
import NavSocialLink from '../NavComponents/NavSocialLink.vue'
import NavToggle from '../NavComponents/NavToggle.vue'

export default {
  components: {
    NavToggle,
    NavMobileLink,
    NavSocialLink,
    NavMobileLocaleSwitcher,
    ButtonBase
  },
  props: {
    mainNavigation: {
      type: Array,
      default: () => []
    },
    buttonNavigation: {
      type: Array,
      default: () => []
    },
    socialNavigation: {
      type: Array,
      default: () => []
    },
    localeNavigation: {
      type: Array,
      default: () => []
    },
    homeLink: {
      type: String,
      default: '/'
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    translation: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isNavMobileOpen: false,
      isNavMobileLocaleSwitcherOpen: false,
      navMobileLinkIdArray: []
    }
  },
  watch: {
    isMobile(isMobile) {
      if (!isMobile) {
        this.closeNavMobile()
      }
    },
    isNavMobileOpen(value) {
      document.body.classList.toggle('nav-mobile-open', value)
      const navMobileRef = this.$refs.navMobile
      value ? disableBodyScroll(navMobileRef) : enableBodyScroll(navMobileRef)
    }
  },
  unmounted() {
    clearAllBodyScrollLocks()
  },
  methods: {
    handleNavMobileLocaleSwitcherToogle(value) {
      this.isNavMobileLocaleSwitcherOpen = value
    },
    handleNavMobileLinkSubdropToggle(id) {
      if (typeof id !== 'number') {
        this.navMobileLinkIdArray = []
      } else if (this.navMobileLinkIdArray.includes(id)) {
        this.navMobileLinkIdArray = this.navMobileLinkIdArray.filter(
          (linkId) => linkId !== id
        )
      } else {
        this.navMobileLinkIdArray = [...this.navMobileLinkIdArray, id]
      }
    },
    closeNavMobile() {
      this.isNavMobileOpen = false
      this.isNavMobileLocaleSwitcherOpen = false
      this.$refs.navMobile.scrollTo({ top: 0 })
      this.navMobileLinkIdArray = []
    }
  }
}
</script>

<style lang="scss">
.nav-mobile {
  $border-color: #e4e8ed;

  &-container > * {
    position: relative;
    z-index: z-index(nav - 1);

    & > svg path {
      fill: currentColor;
    }

    &:is(input[type='checkbox']) {
      @include visually-hidden();
    }
  }

  &-wrapper {
    position: absolute;
    inset: 0;
    z-index: z-index(nav);
    overflow: hidden;
    height: 100vh;
    height: 100svh;
    width: 100%;
    max-width: 100vw;
    pointer-events: none;
  }

  position: absolute;
  inset: 0;
  bottom: unset;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: color(white);
  color: color(black);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transform: translateX(100%);
  transition:
    opacity 0.4s,
    visibility 0.4s,
    transform 0.3s ease;

  #navMobileToggle:checked ~ &-wrapper & {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    transform: translateX(0);
  }

  &__header {
    height: $header-h;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: rem(8px);
    padding-inline: rem($container-padding);

    @if (global-variable-exists('primary')) {
      background-color: color(white);

      & > label svg path {
        fill: color(black);
      }
    } @else {
      background-color: color(tarawera);

      & > label svg path {
        fill: color(white);
      }
    }
  }

  &__logo:is(a) {
    color: color(white);
  }

  :where(& ul) {
    margin: 0;
  }

  &__scrollable {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: rem(32px);
    height: 100%;
    overflow-y: auto;
    color: var(--font-200);
    background-color: var(--bg-100);
    overscroll-behavior: none;
    scrollbar-width: none;

    & ::-webkit-scrollbar {
      display: none;
    }
  }

  &--scroll-locked &__scrollable {
    overflow-y: hidden;
  }

  &__list > * {
    border-bottom: 1px solid $border-color;
  }

  &__social {
    padding-inline: rem($container-padding);

    & > p {
      font-weight: 500;
      margin: 0;
    }
  }

  &__social-list {
    display: flex;
    gap: rem(8px);
    overflow: auto;
    padding-block: rem(12px);
    margin-bottom: rem(2px);
    overscroll-behavior: none;
    scrollbar-width: none;

    & ::-webkit-scrollbar {
      display: none;
    }
  }

  & .nav-mobile-locale-switcher {
    margin-top: auto;
  }

  $button-height: rem(56px);

  &__footer {
    display: flex;
    flex-direction: column;
    gap: rem($container-padding);
    margin-top: auto;
    padding: rem($container-padding);
    background-color: color(white);
    border-top: 1px solid $border-color;

    & .button-base {
      height: $button-height;
      width: 100%;
      margin: 0;

      & > span {
        height: 100%;
      }
    }
  }

  &--with-footer .nav-mobile-locale-switcher {
    --overlay-position: calc(
      #{rem($container-padding)} * (var(--footer-size) + 1) + #{$button-height} *
        var(--footer-size)
    );
  }

  &__header,
  &__footer {
    position: relative;
    z-index: 1;
    flex-shrink: 0;
  }
}
</style>
